/* 
 * @Author: 曹俊杰 
 * @Date: 2020-11-09 15:58:22
 * @Module: 文章详情
 */
 <template>
  <div class="detail"
       v-if="detailData">
    <div class="articleDetail-title">
      {{detailData.moment_content.title}}
    </div>
    <detail-userinfo :data="detailData" />
    <div v-html="articleDetailText"
         style="line-height:30px;"></div>
    <tip-off :data="detailData"
             @onLike="onLike"
             @onTipOff="onTipOff" />
    <column :data="detailData" />
    <comment :data="detailData" />
    <recommend :data="detailData" />
    <menu-list :data="detailData" />
  </div>
</template>
 <script>
import detailMixins from "./detailMixins"
export default {
  components: {},
  mixins: [detailMixins],
  data () {
    return {};
  },
  mounted () { },
  methods: {},
  computed: {
    articleDetailText () {
      return this.detailData.moment_content.content.replace(/<img/g, "<img style='max-width:100%;height:auto;'")
    }
  }
};
 </script>
 <style lang='scss' scoped>
@import url("./style.scss");
.articleDetail-title {
  font-size: 30px;
  margin-top: 16px;
}
</style>